import React, { useEffect, useState } from "react";
import { Modal } from "@fluentui/react";
import {getNumberFromString,isEmpty,isNumOnly} from "../utils/validation";
import styles from "./AddDemandModal.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import {PrimaryButton,DatePicker,CommandBarButton,} from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { TextField } from "@fluentui/react/lib/TextField";
import { Label } from "@fluentui/react/lib/Label";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./DraftEditorResetFix.css";
import boldicon from "../../src/assets/boldicon.svg";
import undoicon from "../../src/assets/undoicon.svg";
import redoicon from "../../src/assets/redoicon.svg";
import { Popup } from "../components/Popup";
import { axiosPrivateCall } from "../constants";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import ComboBox from "../components/ComboBox/ComboBox";
import AssignedDemandModal from "./AssignedDemandModal";

const contractIconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  cursor: "pointer",
});
const closeIconClass = mergeStyles({
  fontSize: 16,
  height: 20,
  width: 20,
  cursor: "pointer",
});
const addButtonStyles = {
  icon: {
    color: "rgb(50, 49, 48)",
    fontSize: 14,
  },
};
const removeIconClass = mergeStyles({
  fontSize: 10,
  height: "20px",
  width: "12px",
  paddingTop: "11px",
  paddingLeft: "20px",
  cursor: "pointer",
  color: "red",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const editorToolbarOptions = {
  options: ["inline", "list", "link", "history"],
  inline: {
    bold: { icon: boldicon, className: undefined },
    options: ["bold", "italic", "underline"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
  link: {
    options: ["link"],
  },
  history: {
    options: ["undo", "redo"],
    undo: { icon: undoicon },
    redo: { icon: redoicon },
  },
}
const getTextFieldStyles = (width, padding, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#a80000" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#a80000" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      width: width,
      marginTop: padding,
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: titleColor,
      fontSize: "12px",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
          paddingLeft: "2px",
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};
const getTextFieldStyles1 = (width, padding, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#E1E5E8" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D7D7D7" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      width: width,
      marginTop: padding,
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: titleColor,
      fontSize: "12px",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
          paddingLeft: "2px",
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};
const getDropdownStyles = (width, marginTop, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#a80000" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#a80000" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    title: {
      height: "29px",
      width: width,
      maxWidth: maxWidth,
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      marginTop: marginTop,
      borderRadius: borderRadius,
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: borderRadius,
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: borderRadius,
        },
      },
    },
  };
};
const calendarClass = (value, error,currentHover ) => {
  return mergeStyleSets({
    root: {
      "*": {
        fontSize: 13,
        height: "29px !important",
        borderColor: error ? "#a80000 !important" : "#E1E5E8 !important",
        borderRadius: "4px",
        lineHeight: "26px !important",
          color: error ? "#a80000" : (value.length === 0 && "LightGray !important" || "#484848 !important")
        },
    },
    field: {
      color: value.length === 0 ? "LightGray !important" : "black !important",
      fontSize: 12,
      input: {
        color: value.length === 0 ? "LightGray !important" : "black !important",
        "&::placeholder": {
          color: value.length === 0 ? "LightGray !important" : "black !important",
        },
      },
    },
    icon: {
      height: "14px !important",
      width: "8px !important",
      left: "90%",
      padding: "0px 0px",
      scale: "90%",
    },
    statusMessage: { marginBottom: "-25px" },
  });
};
const dropDownStatus = [
  { key: "open", text: "Open" },
  { key: "close", text: "Close" },
  { key: "on hold", text: "On Hold" },
  { key: "in progress", text: "In Progress" },
];
const dropDownPriority = [
  { key: "low", text: "Low" },
  { key: "medium", text: "Medium" },
  { key: "high", text: "High" },
];
const dropDownNoticePeriod = [
  { key: "lt15", text: "Immediate" },
  { key: "lt15", text: "< 15 Days" },
  { key: "lt30", text: "< 30 Days" },
  { key: "lt60", text: "< 60 Days" },
  { key: "gt60", text: "> 60 Days" },
];
const modeOfHireDropdown = [
  { key: "C2H (contract to Hire)", text: "C2H (contract to Hire)" },
  { key: "Permanent", text: "Permanent" },
];
const initialState = {
  job_title: "",
  assigned_to: [],
  status: "",
  no_of_positions: "",
  priority: "",
  client: "",
  job_description: "",
  additional_details: "",
  due_date: "",
  notice_period: "",
  minimum_experience_months: "",
  minimum_experience_years: "",
  maximum_experience_months: "",
  maximum_experience_years: "",
  mode_of_hire: "",
  vendor_name: "",
  poc_vendor: "",
  job_rr_id: "",
};
const AddDemandModal = (props) => {
  const { isModalOpen, setIsModalOpen, showMessageBar, setShowMessageBar } = props;
  const setMatchProfile = props.setMatchProfile;
  const [showPopup, setShowPopup] = useState(false);
  const [currentHover, setCurrentHover] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShrunk, setIsModalShrunk] = useState(false);
  const [dropDownClients, setDropDownClients] = useState([]);
  const [dropDownSkills, setDropDownSkills] = useState([]);
  const [showAssignedDemandmodal, setShowAssignedDemandModal] = useState(false);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [editorState2, setEditorState2] = React.useState(() => EditorState.createEmpty());
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
     axiosPrivateCall
      .get(`/api/v1/client/listClients`)
      .then((res) => {
        let buffer = res.data;
        let dropdown_data = buffer.map((obj) => {
          return { key: obj.company_name, text: obj.company_name };
        });
        setDropDownClients(dropdown_data);
      })
      .catch((e) => {
        console.log(e);
      });
    axiosPrivateCall
      .get(`/api/v1/skill/listSkills`)
      .then((res) => {
        let buffer = res.data;
        let dropdown_data = buffer.map((obj) => {
          return { key: obj.skill_name, text: obj.skill_name };
        });
        setDropDownSkills(dropdown_data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    const contentLength = editorState.getCurrentContent().getPlainText().trim().length;
    if (contentLength === 0) {
      setDemandData((prevData) => {
        return {
          ...prevData,
          job_description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          additional_details: draftToHtml(convertToRaw(editorState2.getCurrentContent())),
        };
      });
    } else if (contentLength < 10) {
      setDemandData((prevData) => {
        return {
          ...prevData,
          job_description: "",
          additional_details: draftToHtml(convertToRaw(editorState2.getCurrentContent())),
        };
      });
    } else if (contentLength >= 10) {
      setDemandData((prevData) => {
        return {
          ...prevData,
          job_description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          additional_details: draftToHtml(convertToRaw(editorState2.getCurrentContent())),
        };
      });
    }
  }, [editorState, editorState2]);
  const handleEditorStateChange = (newEditorState) => {
    const content = newEditorState.getCurrentContent().getPlainText().trim();
    if (content.length === 0) {
      setErrorMessage("");
    } else if (content.length < 10) {
      setErrorMessage("Minimum 10 characters Required");
    } else {
      setErrorMessage("");
    }
    setEditorState(newEditorState);
  };
  function closeHandler() {
    setShowPopup(!showPopup);
  }
  const escKeyHandler = (event) => {
    if (event.key === "Escape") {
      closeHandler();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escKeyHandler, { capture: true });
    return () => {
      document.removeEventListener("keydown", escKeyHandler, { capture: true });
    };
  }, []);
  const [demandData, setDemandData] = useState({
    ...initialState,
    skillset: [
      {
        skill: "",
        years: "",
        months: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    ...initialState,
    skillset: [
      {
        skill: "",
        years: "",
        months: "",
      },
    ],
  });
  const dropDownHandler = (e, item, name) => {
    setDemandData((prevData) => {
      return {
        ...prevData,
        [name]: item.text,
      };
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const skillSetInputHandler = (e, name, index, arr) => {
    const skillsetArr = arr;
    let value = e.target.value;
    let isSkillInputValid = true;
    if (name === "years") {
      if (!isNumOnly(value) || value > 99) {
        isSkillInputValid = false;
      }
      if (isEmpty(value)) {
        isSkillInputValid = true;
      }
    }
    if (name === "months") {
      if (!isNumOnly(value) || value > 12) {
        isSkillInputValid = false;
      }
      if (isEmpty(value)) {
        isSkillInputValid = true;
      }
    }
    if (isSkillInputValid) {
      skillsetArr[index][name] = value;
      setDemandData((prevData) => {
        return {
          ...prevData,
          skillset: skillsetArr,
        };
      });
      setErrors((prevData) => {
        const skillsetArr = prevData["skillset"];
        skillsetArr[index][name] = "";
        return {
          ...prevData,
          skillset: skillsetArr,
        };
      });
    }
  };
  const removeSkill = (skillData, index, arr) => {
    if (index === 0) return;
    const newskillsetArr = arr.filter((val) => val !== skillData);
    setDemandData((prevData) => {
      return {
        ...prevData,
        skillset: newskillsetArr,
      };
    });
    const newskillsetErrArr = errors.skillset.filter((val, i) => i !== index);
    setErrors((prevData) => {
      return {
        ...prevData,
        skillset: newskillsetErrArr,
      };
    });
  };
  const dateHandler = (date, name) => {
    setDemandData((prevData) => {
      return {
        ...prevData,
        [name]: date,
      };
    });
    setErrors({
      ...errors,
      [name]: "",
    });
    setCurrentHover("");
  };
  const inputChangeHandler = (e, inputName) => {
    e.preventDefault();
    const { value } = e.target;
    let inputValue = value;
    let isInputValid = true;
    if (inputName === "no_of_positions") {
      if (!isNumOnly(value)) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }
  }
   
    if (inputName === "minimum_experience_years") {
        if (!isNumOnly(value) || value > 99) {
          isInputValid = false;
        }
        if (isEmpty(value)) {
          isInputValid = true;
        }
    }
      if (inputName === "minimum_experience_months") {
        if (!isNumOnly(value) || value > 12) {
          isInputValid = false;
        }
        if (isEmpty(value)) {
          isInputValid = true;
        }   
    }
    if (inputName === "maximum_experience_years") {
      if (!isNumOnly(value) || value > 99) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }
  }
    if (inputName === "maximum_experience_months") {
      if (!isNumOnly(value) || value > 12) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }   
  }
    if (isInputValid) {
      setDemandData({
        ...demandData,
        [inputName]: inputValue,
      });
      setErrors({
        ...errors,
        [inputName]: "",
      });
    }
  };
  const addSkillSet = () => {
    const skillsetArr = demandData.skillset;
    skillsetArr.push({
      skill: "",
      years: "",
      months: "",
    });
    setDemandData((prevData) => {
      return {
        ...prevData,
        skillset: skillsetArr,
      };
    });
    const skillsetErrArr = errors.skillset;
    skillsetErrArr.push({
      skill: "",
      years: "",
      months: "",
    });
    setErrors((prevData) => {
      return {
        ...prevData,
        skillset: skillsetErrArr,
      };
    });
  };
  const modalSizeHandler = () => {
    setIsModalShrunk(!isModalShrunk);
  };
  const getErrorObj = (obj) => {
    const errorObj = {};
    let min_month = parseInt(getNumberFromString(obj.minimum_experience_months));
    let max_month = parseInt(getNumberFromString(obj.maximum_experience_months));
    let min_year = parseInt(getNumberFromString(obj.minimum_experience_years));
    let max_year = parseInt(getNumberFromString(obj.maximum_experience_years));
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        errorObj[key] = [];
        value.map((data, index) => {
          let newErrObj = {};
          Object.keys(data).map((key) => {
            if (isEmpty(data[key])) {
              return (newErrObj[key] = " ");
            } else {
              return (newErrObj[key] = "");
            }
          });
          return (errorObj[key][index] = newErrObj);
        });
      }
       else if (isEmpty(value)) {
        errorObj[key] = " ";
      } else {
        errorObj[key] = "";
      }
      if (min_year > max_year) {
        errorObj["maximum_experience_years"] = " Min exp exceeds max exp.";
      } else if (key === "maximum_experience_months") {
        const min_experience_total = min_year * 12 + min_month;
        const max_experience_total = max_year * 12 + max_month;
        if (min_experience_total > max_experience_total) {
          errorObj[key] = "Min exp exceeds max exp.";
        }
      }
    }
    return errorObj;
  };
  const sanitizer = (data) => {
    const sanitizedData = {};
    Object.keys(data).forEach((key) => {
      if (key === "skillset") {
        sanitizedData[key] = [];
        data["skillset"].map((skillObj, index) => {
          sanitizedData[key].push({});
          sanitizedData[key][index]["skill"] = skillObj["skill"];
          sanitizedData[key][index]["exp"] = Number(skillObj["years"] * 12) + Number(skillObj["months"]);
        });
        return;
      }
      if (key === "minimum_experience_years") {
        return (sanitizedData["minimum_experience"] = sanitizedData["minimum_experience"]
          ? parseInt(data[key]) * 12 + sanitizedData["minimum_experience"]
          : parseInt(getNumberFromString(data[key]) * 12));
      }
      if (key === "minimum_experience_months") {
        return (sanitizedData["minimum_experience"] = sanitizedData["minimum_experience"]
          ? parseInt(data[key]) + sanitizedData["minimum_experience"]
          : parseInt(getNumberFromString(data[key])));
      }
      if (key === "maximum_experience_years") {
        return (sanitizedData["maximum_experience"] = sanitizedData["maximum_experience"]
          ? parseInt(data[key]) * 12 + sanitizedData["maximum_experience"]
          : parseInt(getNumberFromString(data[key]) * 12));
      }
      if (key === "maximum_experience_months") {
        return (sanitizedData["maximum_experience"] = sanitizedData["maximum_experience"]
          ? parseInt(data[key]) + sanitizedData["maximum_experience"]
          : parseInt(getNumberFromString(data[key])));
      }
      sanitizedData[key] = data[key];
    });
    return sanitizedData;
  };
  const createDemand = () => {
    axiosPrivateCall
      .post("/api/v1/demand/createDemand", sanitizer(demandData))
      .then((res) => {
        setIsModalOpen(!isModalOpen);
        setMatchProfile(res.data.match);
        setIsLoading(false);
        setShowMessageBar(!showMessageBar);
        setShowMessageBar(true);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false); 
      });
  };
  const submitHandler = () => {
    if (isLoading) return; 
    setIsLoading(true);
    const errorObj = getErrorObj(demandData);
    setErrors(errorObj);
    if (isDemandDataValid(demandData)) {
      createDemand();
    } else {
      setIsLoading(false);
    }
  };
  const isDemandDataValid = (obj) => {
    const excludedFields = ["assigned_to","vendor_name", "poc_vendor", "job_rr_id"]; 
    let min_month = parseInt(getNumberFromString(obj.minimum_experience_months));
    let max_month = parseInt(getNumberFromString(obj.maximum_experience_months));
    let min_year = parseInt(getNumberFromString(obj.minimum_experience_years));
    let max_year = parseInt(getNumberFromString(obj.maximum_experience_years));
    for (const [key, value] of Object.entries(obj)) {
      if (excludedFields.includes(key)) {
        continue;
      }
      if (Array.isArray(value)) {
        return value.every((data, index) => {
          return Object.values(data).every((val) => !isEmpty(val));
        });
      }
      if (isEmpty(value)) {
        return false;
      }
      if (errors.maximum_experience_years === "Min exp exceeds max exp.") {
        return false;
      }
      const min_experience_total = min_year * 12 + min_month;
      const max_experience_total = max_year * 12 + max_month;
      if (min_experience_total > max_experience_total) {
        return false;
      }
    }
    return true;
  };  
  let minDate = new Date();
  function isValidInput(input) {
    const regex = /^[a-zA-Z\s]*$/;
    return regex.test(input);
  }
  const handlePopupClose = () => {
    const hasValues = Object.values(demandData).some((value) => {
      if (typeof value === "string" && value.trim() !== "" && !isHtmlTag(value)) {
        return true;
      } else if (Array.isArray(value) && value.length > 0) {
        const isObjectEmpty = value.every((obj) => {
          return Object.values(obj).every((propValue) => propValue.trim() === '');
        });
        return !isObjectEmpty; 
      }
      return false;
    });
    if (hasValues) {
      setShowPopup(true);
    } else {
      setShowPopup(!showPopup);
      setIsModalOpen(!isModalOpen);
    }
  };
  function isHtmlTag(str) {
    const tagRegex = /<([a-z][a-z\d]*)\b[^>]*>(.*?)<\/\1>/i;
    return tagRegex.test(str);
  }
  function isHtmlTag(str) {
    const tagRegex = /<([a-z][a-z\d]*)\b[^>]*>(.*?)<\/\1>/i;
    return tagRegex.test(str);
  }
  return (
    <div>
         {showAssignedDemandmodal && (
        <AssignedDemandModal
          data={demandData.assigned_to}
          isModalOpen={showAssignedDemandmodal}
          setIsModalOpen={setShowAssignedDemandModal}
        />
      )}
      {
        <Popup
          resetState={() => ""}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }
      <Modal
        id="Modal12"
        scrollableContentClassName={styles.adddemand_modal_scrollable_content}
        containerClassName={`${
          isModalShrunk ? styles.adddemand_modal_container_shrunk : styles.adddemand_modal_container
        }`}
        isOpen={isModalOpen}
      >
        <div className={styles.adddemand_modal_header_container}>
          <div className={styles.header_tag_expand_close_icon_container}>
            <div className={styles.header_tag_container}>Demand</div>
            <div className={styles.header_expand_close_icon_container}>
              <div onClick={modalSizeHandler} className={styles.header_expand_icon_container}>
                {isModalShrunk ? (
                  <Icon iconName="FullScreen" className={contractIconClass} />
                ) : (
                  <Icon iconName="BackToWindow" className={contractIconClass} />
                )}
              </div>
              <div onClick={ handlePopupClose} className={styles.header_close_icon_container}>
                <Icon iconName="ChromeClose" className={closeIconClass} />
              </div>
            </div>
          </div>
          <div className={styles.AddLabel}>Add Demand</div>
          <div className={styles.header_content_container}>
            <div className={styles.header_content_job_description_unassigned_save_container}>
              <div className={styles.header_content_job_description_unassigned_container}>
                <div className={styles.header_content_job_description_container}>
                  <div className={styles.job_label} onClick={() => setCurrentHover("job_title")}>
                    <Label className={styles.name_label_style} required>
                      Job Requirement Title
                    </Label>
                    <TextField
                      value={demandData.job_title}
                      placeholder="Enter the Job Requirement Title"
                      onChange={(e) => {
                        inputChangeHandler(e, "job_title");
                        setCurrentHover("");
                      }}
                      resizable={false}
                      styles={(props) =>
                        getTextFieldStyles(
                          "35vw", 
                           null,
                          "35vw",
                          props,
                          currentHover,
                          errors.job_title,
                          demandData.job_title
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={styles.header_save_close_btns_container}>
              <div
                  id="personaId"
                  className={styles.unassigned_container}
                >
                  <div className={styles.unassigned_title_icon_container}>
                  <div className={styles.unassigned_title_container}>
                    {`Unassigned`}
                    </div>      
                  </div>
                </div>
                <PrimaryButton
  className={styles.btnStyle}
  onClick={submitHandler}
  text="Save & Close"
  iconProps={{ iconName: "Save" }}
  disabled={isLoading} 
/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.main_filter_options_container}>
          <div className={styles.main_filter_options_sub_container}>
            <div className={styles.main_role_dropdown_container}>
              <div className={styles.main_role_title}>
                <Label className={styles.required_field} required>
                  Status
                </Label>
              </div>
              <div className={styles.sec_div} onClick={() => setCurrentHover("status")}>
                <Dropdown
                  name="status"
                  onChange={(e, item) => {
                    dropDownHandler(e, item, "status");
                    setCurrentHover("");
                  }}
                  placeholder="Select"
                  notifyOnReselect
                  styles={(props) =>
                    getDropdownStyles(
                      null, 
                      null,
                      null, 
                      props,
                      currentHover,
                      errors.status, demandData.status,
                      "status"
                    )
                  }
                  options={dropDownStatus}
                />
              </div>
            </div>
            <div className={styles.main_role_dropdown_container}>
              <div className={styles.main_role_title1}>
                <Label className={styles.required_field} required>
                  No of Positions
                </Label>
              </div>
              <div onClick={() => setCurrentHover("no_of_positions")}>
                <TextField
                  value={demandData.no_of_positions}
                  onChange={(e) => {
                    inputChangeHandler(e, "no_of_positions");
                  }}
                  placeholder={"Enter the count"}
                  styles={(props) =>
                    getTextFieldStyles(
                      "190px", 
                       null, 
                      "190px", 
                      props,
                      currentHover,
                      errors.no_of_positions,
                      demandData.no_of_positions
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className={styles.main_filter_options_sub_container}>
            <div className={styles.main_role_dropdown_container}>
              <div className={styles.main_role_title2}>
                <Label className={styles.required_field} required>
                  Priority
                </Label>{" "}
              </div>
              <div  className={styles.sec_div} onClick={() => setCurrentHover("priority")}>
                <Dropdown
                  onChange={(e, item) => {
                    dropDownHandler(e, item, "priority");
                    setCurrentHover("");
                  }}
                  placeholder="Select"
                  notifyOnReselect
                  styles={(props) =>
                    getDropdownStyles(
                      null, 
                      null, 
                      null, 
                      props,
                      currentHover,
                      errors.priority,
                      demandData.priority,
                      "priority"
                    )
                  }
                  options={dropDownPriority}
                />
              </div>
            </div>
            <div className={styles.main_role_dropdown_container}>
              <div className={styles.main_role_title3}>
                <Label className={styles.required_field} required>
                  Client
                </Label>
              </div>
              <div onClick={() => setCurrentHover("client")}>
                <ComboBox
                  type="text"
                  name="client"
                  inputChangeHandler={inputChangeHandler}
                  setInfo={setDemandData}
                  setInfoErrors={setErrors}
                  value={demandData.client}
                  dropdown={dropDownClients}
                  textfield="color"
                  placeholder="Enter client name"
                  comboStyles={(props) =>
                      getTextFieldStyles(
                        "190px", 
                         null, 
                        "190px", 
                        props,
                        currentHover,
                        errors.client,
                        demandData.client
                      )
                    }                 
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.job_description_container}>
            <div className={styles.job_description}>
            <div className={styles.main_basic_information_title}>
            <Label className={styles.p_job} > 
                Job Description
                 </Label> 
              </div>
              <div
                className={`${styles.main_wysiwyg_container} ${errors.job_description
                    ? styles.main_wysiwyg_container_error
                    : ""
                  } 
								${demandData.job_description.length > 8
                    ? styles.main_wysiwyg_container_focus
                    : ""
                  }`}
              >
                <Editor
                  wrapperClassName={styles.editor_wrapper}
                  toolbar={editorToolbarOptions}
                  toolbarOnFocus
                  toolbarClassName={styles.editor_toolbar}
                  editorClassName={styles.editor_editor}
                  placeholder="Click to add description (Minimum 10 characters)"
                  editorState={editorState}
                  onEditorStateChange={handleEditorStateChange}
                />
              </div>
            </div>
            <div className={styles.error}>{errorMessage && <p>{errorMessage}</p>}</div>
<div className={styles.additional_description}>
            <div className={styles.main_basic_information_title}>
                <Label className={styles.p_additional} > 
                Additional Information
                 </Label> 
              </div>
              <div
                className={`${styles.main_wysiwyg_container} ${demandData.additional_details.length > 8
                    ? styles.main_wysiwyg_container_focus
                    : ""
                  }`}
              >
                 <Editor
                  wrapperClassName={styles.editor_wrapper}
                  placeholder="Click to add description"
                  toolbarOnFocus
                  toolbarClassName={styles.editor_toolbar}
                  editorClassName={styles.editor_editor}
                  toolbar={editorToolbarOptions}
                  editorState={editorState2}
                  onEditorStateChange={(editorState2) =>
                    setEditorState2(editorState2)
                  }
                />               
              </div>
            </div>
          </div>
          <div className={styles.demand_description_container}>
            <div className={styles.demand_information}>
              <p className={styles.p_job_description}>Demand Information</p>
              <div className={styles.two_sub}>
                <div className={styles.two_sub_left}>
                  <Label className={styles.customer_label_style} required>
                    Due Date
                  </Label>
                  <div onClick={() => setCurrentHover("due_date")}>
                    <DatePicker
                      placeholder={"DD/MM/YYYY"}
                      minDate={minDate}
                      styles={calendarClass(demandData.due_date, errors.due_date,"due_date")}
                      onSelectDate={(date) => {
                        dateHandler(date, "due_date");
                      }}
                    />
                  </div>
                </div>
                <div className={styles.two_sub_right}>
                  <Label className={styles.customer_label_style} required>Notice Period</Label>
                  <div onClick={() => setCurrentHover("notice_period")}>
                    <Dropdown
                      onChange={(e, item) => {
                        dropDownHandler(e, item, "notice_period");
                        setCurrentHover("");
                      }}
                      placeholder={"Select"}
                      notifyOnReselect
                      styles={(props) =>
                        getDropdownStyles(
                          null, 
                          null, 
                          null, 
                          props,
                          currentHover,
                          errors.notice_period,
                          demandData.notice_period,
                          "notice_period"
                        )
                      }
                      options={dropDownNoticePeriod}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.data_month_container}>
                <div className={styles.minimum_exp_container}>
                  <Label className={styles.min_exp_label} required>
                    Minimum Experience
                  </Label>
                  <div className={styles.experience_div}>
                  <TextField
                      placeholder="00"
                      value={demandData.minimum_experience_years}
                      onChange={(e) => {
                        inputChangeHandler(e, "minimum_experience_years");
                      }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "37px",
                           null, 
                          "37px", 
                          props,
                          currentHover,
                          errors.minimum_experience_years,
                          demandData.minimum_experience_years,
                          "minimum_experience_years"
                        )
                      }                      
                    />
                    <Label className={styles.experience_text}>Years</Label>
                    <TextField
                      placeholder="00"
                      value={demandData.minimum_experience_months}
                      onChange={(e) => {
                        inputChangeHandler(e, "minimum_experience_months");
                      }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "37px", 
                           null, 
                          "37px",
                          props,
                          currentHover,
                          errors.minimum_experience_months,
                          demandData.minimum_experience_months,
                          "minimum_experience_months"
                        )
                      }
                    />
                    <Label className={styles.experience_text}>Months</Label>
                  </div>
                </div>
                <div className={styles.maximum_exp_container}>
                  <div>
                    <Label className={styles.min_exp_label} required>
                      Maximum Experience
                    </Label>
                    <div className={styles.experience_div}>
                    <TextField
                        placeholder="00"
                        value={demandData.maximum_experience_years}
                        onChange={(e) => {
                          inputChangeHandler(e, "maximum_experience_years");
                        }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "37px", 
                             null, 
                            "37px",
                            props,
                            currentHover,
                            errors.maximum_experience_years,
                            demandData.maximum_experience_years,
                            "maximum_experience_years")
                        }
                      />
                      <Label className={styles.experience_text}>Years</Label>
                      <TextField
                        placeholder="00"
                        value={demandData.maximum_experience_months}
                        onChange={(e) => {
                          inputChangeHandler(e, "maximum_experience_months");
                        }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "37px", 
                             null, 
                            "37px",
                            props,
                            currentHover,
                            errors.maximum_experience_months,
                            demandData.maximum_experience_months,
                            "maximum_experience_months"
                          )
                        }
                      />
                      <Label className={styles.experience_text}>Months</Label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mode_of_hire}>
                <Label className={styles.required_field} required>
                  Mode of Hire
                </Label>
              </div>
              <div className={styles.width_of_mode}>
                <div onClick={() => setCurrentHover("mode_of_hire")}>
                  <Dropdown
                    onChange={(e, item) => {
                      dropDownHandler(e, item, "mode_of_hire");
                      setCurrentHover("");
                    }}
                    placeholder="Select"
                    notifyOnReselect
                    styles={(props) =>
                      getDropdownStyles(
                        null, 
                        null, 
                        null, 
                        props,
                        currentHover,
                        errors.mode_of_hire,
                        demandData.mode_of_hire,
                        "mode_of_hire"
                      )
                    }
                    options={modeOfHireDropdown}
                  />
                </div>
              </div>
            </div>
            <div className={styles.vendor_information}>
              <p className={styles.p_job_description}>Vendor Information</p>
              <div className={styles.two_sub}>
                <div className={styles.two_sub_left}>
                  <Label className={styles.customer_label_style} >
                    Vendor Name
                  </Label>
                  <div   onClick={() => setCurrentHover("vendor_name")}>
                  <TextField
                      value={demandData.vendor_name}
                      onChange={(e) => {
                        inputChangeHandler(e, "vendor_name");
                        setCurrentHover("");
                      }}
                      placeholder={"Enter the Name"}
                      styles={(props) =>
                        getTextFieldStyles1(
                          "190px", 
                           null, 
                          "190px", 
                          props,
                          currentHover,
                          errors.vendor_name,
                          demandData.vendor_name
                        )
                      }
                    />
                  </div>
                </div>
                <div className={styles.two_sub_right}>
                  <Label className={styles.customer_label_style}>Point of contact Vendor</Label>
                  <div onClick={() => setCurrentHover("notice_period")}>
                  <TextField
                          value={demandData.poc_vendor}
                          onChange={(e) => {
                            if (isValidInput(e.target.value)) {
                              inputChangeHandler(e, "poc_vendor");
                              setCurrentHover("");
                            }
                          }}
                          placeholder={"POC Vendor Name"}
                          styles={(props) =>
                            getTextFieldStyles1(
                              "190px", 
                               null, 
                              "190px", 
                              props,
                              currentHover,
                              errors.poc_vendor,
                              demandData.poc_vendor
                            )
                          }
                        />
                  </div>
                </div>
              </div>
              <div className={styles.job_rr_id}>
                <Label className={styles.required_field} >
                  Job / RR ID
                </Label>
                <div onClick={() => setCurrentHover("job_rr_id")}>
                <TextField
                          value={demandData.job_rr_id}
                          onChange={(e) => {
                            inputChangeHandler(e, "job_rr_id");
                          }}
                          placeholder={"Enter ID"}
                          styles={(props) =>
                            getTextFieldStyles1(
                              "190px", 
                               null, 
                              "190px",
                              props,
                              currentHover,
                              errors.job_rr_id,
                              demandData.job_rr_id
                            )
                          }
                        />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.skill_set_container}>
            <div className={styles.skill_sets}>
              <div className={styles.skill_and_add}>
                <p className={styles.p_skill}>Skill Sets</p>
                <div onClick={addSkillSet} className={styles.main_right_add_icon_container}>
                  <CommandBarButton styles={addButtonStyles} iconProps={{ iconName: "Add" }} text="Add " />
                </div>
              </div>
              <div className={styles.main_right_skill_set_experience_container}>
                <div className={styles.main_right_skill_set_title_dropdown_container}>
                  <div className={styles.main_right_skill_set_title_sub_title}>
                    <Label className={styles.required_field} required>
                      Skill Set
                    </Label>
                  </div>
                  {demandData.skillset.map((skillData, index, arr) => (
                    <div key={index} className={styles.main_right_skill_set_dropdown_container}>
                      <ComboBox
                        type="text"
                        name={`skill`}
                        index={index}
                        skillArr={true}
                        arr={arr}
                        setInfo={setDemandData}
                        setInfoErrors={setErrors}
                        inputChangeHandler={skillSetInputHandler}
                        value={demandData.skillset[index]?.skill}
                        dropdown={dropDownSkills}
                        placeholder={
                          index === 0 ? "Primary Skill Set" : index === 1 ? "Secondary Skill Set" : "Other Skills"
                        }
                        comboStyles={(props) =>
                          getTextFieldStyles(
                            "190px", 
                             null, 
                            "190px", 
                            props, currentHover, errors.skillset[index]?.skill, demandData.skillset[index]?.skill)}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.main_right_skill_experience_container}>
                  <div className={styles.main_right_skill_experience_title_sub_title}>
                    <Label className={styles.required_field} required>
                      Relevant Skill Experience
                    </Label>
                  </div>
                  {demandData.skillset.map((skillData, index, arr) => (
                    <div key={index} className={styles.main_right_skill_experience_dropdown_remove_icon_container}>
                      <div className={styles.main_right_skill_experience_dropdown_container}>
                        <div className={styles.skill_alignment} onClick={() => setCurrentHover(`years${index}`)}>
                          <TextField
                            value={demandData.skillset[index]?.years}
                            onChange={(e) => {
                              skillSetInputHandler(e, "years", index, arr);
                            }}
                            placeholder="00"
                            styles={(props) =>
                              getTextFieldStyles(
                                "37px", 
                                 null, 
                                "37px",
                                props,
                                currentHover,
                                errors.skillset[index]?.years,
                                demandData.skillset[index]?.years, `years${index}`
                              )
                            }
                          />
                          <Label className={styles.experience_text}>Years</Label>
                        </div>
                        <div className={styles.skill_alignment}>
                          <TextField
                            value={demandData.skillset[index]?.months}
                            onChange={(e) => {
                              skillSetInputHandler(e, "months", index, arr);
                            }}
                            placeholder="00"
                            styles={(props) =>
                              getTextFieldStyles(
                                "37px",
                                 null,
                                "37px",
                                props,
                                currentHover,
                                errors.skillset[index]?.months,
                                demandData.skillset[index]?.months, `months${index}`)
                            }
                          />
                          <Label className={styles.experience_text}>Months</Label>
                        </div>
                        {index !== 0 && (
                          <Icon iconName="ChromeClose" className={removeIconClass} onClick={() => removeSkill(skillData, index, arr)} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.linked_candidates}>
              <p className={styles.p_linked}>Linked Candidate</p>
              <a href="#" className={styles.noLinked}>
                  No Linked Candidates
                </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddDemandModal;